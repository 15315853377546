import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';
import swvl from '../../assets/images/swvl/swvl-banner.webp';
import swvlOne from '../../assets/images/swvl/swvl-1.webp';
import swvlTwo from '../../assets/images/swvl/swvl-2.webp';
import swvlThree from '../../assets/images/swvl/swvl-3.webp';
import swvlFour from '../../assets/images/swvl/swvl-4.webp';
import swvlFive from '../../assets/images/swvl/swvl-5.webp';
import swvlSix from '../../assets/images/swvl/swvl-6.webp';
import swvlSevenA from '../../assets/images/swvl/swvl-7-a.webp';
import swvlSevenB from '../../assets/images/swvl/swvl-7-b.webp';
import swvlEight from '../../assets/images/swvl/swvl-8b.webp';
import swvlClientImageOne from '../../assets/images/swvl/swvl-client-image-1.webp';
import swvlClientImageTwo from '../../assets/images/swvl/swvl-client-image-2.webp';

import quotationBorder from '../../assets/images/bed-bath-beyond/quotation-border.svg';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';
import pressIcon from '../../assets/images/jobbox/press-icon.svg';

class Swvl extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section>
            <div className="swvl-banner">
              <img src={swvl} alt="banner" />
            </div>
          </section>
          <section className="container jobbox-section-portfolio">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">Tech-Enabled Mass Transit Solutions</div>
                <div className="subtitle section-margin">
                  Building the technology to offer intercity, intracity, B2B and B2G transportation
                  products and services
                </div>
                <div className="client-title section-margin">
                  CLIENT | <span style={{color: '#FC153B'}}>SW</span>
                  <span style={{color: '#FFDE14'}}>V</span>
                  <span style={{color: '#FC153B'}}>L</span>
                </div>

                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Augment their in-house product team to build new features and improve existing
                      solutions to help with the company’s scaling efforts across the Middle East,
                      Africa, and Asia.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Provided four squads that worked in six key areas: M-pesa and EBANX
                      integrations, refund to source capability, partners portal development, supply
                      pricing automation, and dispatcher & ETA optimization.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>
                    <p className="desc">
                      Every solution Tintash built helped solve a critical problem for Swvl. Supply
                      pricing automation, for instance, helped save thousands of man hours while
                      achieving a 98% accuracy of results.
                    </p>
                  </div>
                </div>
                <div className="press-section section-margin">
                  <div className="d-flex align-items-center">
                    {' '}
                    <img className="icon " src={pressIcon} alt="" />
                    <div className="subheading ml-2">Press</div>
                  </div>
                  <p className="desc">
                    <a
                      className="press-desc"
                      href="https://techcrunch.com/2021/10/15/meet-the-startups-changing-mobility-for-emerging-middle-classes/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Meet the startups changing mobility for emerging middle classes
                    </a>{' '}
                    - TechCrunch
                    <br />
                    <a
                      className="press-desc"
                      href="https://www.zawya.com/en/press-release/swvl-first-15bln-unicorn-from-the-middle-east-to-list-on-nasdaq-s4bsnxzx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Swvl, first $1.5bln unicorn from the Middle East to list on Nasdaq
                    </a>{' '}
                    - Zawya
                  </p>
                </div>
                {/* <div className="video-container section-margin">
                  <iframe
                    src="https://www.youtube.com/embed/wM2BmP0MEGY"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div> */}
                <div className="col-11 mr-auto ml-auto">
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">
                      Client&apos;s brief background and business problem
                    </div>
                    <p className="description">
                      <a
                        className="description"
                        href="https://www.swvl.com/"
                        style={{textDecoration: 'underline'}}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Swvl
                      </a>{' '}
                      , the renowned global provider of transformative tech-enabled mass transit
                      solutions and ridesharing services, wanted to partner with software
                      development companies to further improve their technology offerings.{' '}
                      <a
                        className="description"
                        href="https://www.zawya.com/en/press-release/swvl-first-15bln-unicorn-from-the-middle-east-to-list-on-nasdaq-s4bsnxzx"
                        style={{textDecoration: 'underline'}}
                        target="_blank"
                        rel="noreferrer"
                      >
                        The first Middle Eastern unicorn to be listed on Nasdaq
                      </a>
                      , the Dubai-based Egyptian startup was on an ambitious growth trajectory with
                      expansion plans for the Middle East, Africa and Asia.
                    </p>
                    <br />
                    <p className="description">
                      Swvl was looking to onboard technology solution providers that could augment
                      their in-house product development team. The collaboration would speed up the
                      implementation of new features and help address their emerging market needs in
                      a timely manner.
                    </p>
                    <br />

                    <p className="description">
                      The selection criteria was pretty clear. The shortlisted candidates had to be
                      stellar engineers, teams with excellent communication and problem-solving
                      skills to achieve synergy with the company’s existing product efforts.
                      Following a series of written tests and elaborate interviews, Tintash was
                      selected as Swvl’s technology partner.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-12 mr-auto ml-auto">
                      <img className="w-100" src={swvlOne} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      Based on our Team Augmentation model, Tintash provided four separate
                      engineering squads that worked on different problem sets for the client. Each
                      squad specialized in specific skills and worked under an Engineering Manager
                      (EM) from Tintash, who in turn reported to a Senior Engineering Manager (SEM)
                      from Swvl.
                    </p>
                    <br />
                    <p className="description">
                      Throughout the course of this project, the squads would schedule syncups to
                      report on daily progress. Any necessary feedback was communicated either
                      through internal communication channels or via{' '}
                      <a
                        className="description"
                        href="https://camaradly.com/"
                        style={{textDecoration: 'underline'}}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Camaradly
                      </a>{' '}
                      (a P2P employee engagement platform developed by Tintash).
                    </p>
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex mt-5 mr-auto ml-auto section-margin">
                    <div className="col-lg-6 p-0 ">
                      <p className="description">
                        All in all, our squads worked on implementing the following feature sets:
                      </p>
                      <br />
                      <ol className="description" type="1">
                        <li>Mpesa</li>
                        <li>EBANX</li>
                        <li>Refund to Source</li>
                        <li>Partners Portal</li>
                        <li>Supply Pricing</li>
                        <li>Dispatcher and ETA Optimization</li>
                      </ol>
                    </div>
                    <div className="col-lg-6 p-0 d-flex justify-content-center">
                      <img style={{maxWidth: '200px'}} src={swvlTwo} alt="swvl" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">M-pesa</div>
                    <p className="description">
                      Swvl wanted to transition the bookings process from cash payments to cashless
                      transactions in the Kenyan region. For this, they were looking to integrate
                      M-pesa, Kenya’s leading mobile phone-based money transfer and payments
                      service, as a direct payment option in their app’s existing booking workflows.
                    </p>
                    <br />
                    <p className="description">
                      There were numerous challenges involved in implementing this feature. Since
                      cash payments represented a dominant share of the region’s overall revenue, a
                      seamless transition was essential to keeping the impact on revenue minimal. An
                      alternative had to be offered to retain customers during the transition. The
                      whole process had to be engineered in as little time as possible while also
                      ensuring compliance with the local regulatory requirements.
                    </p>
                    <br />
                    <p className="description">
                      Our team worked on a solution that required minimal changes to roll out the
                      M-pesa payment option. We reduced the unnecessary steps introduced by the
                      discontinuation of cash payments. These steps involved requiring Kenyan users
                      to make payments through their Swvl wallets, which had to be topped up using
                      M-pesa – the local mobile wallet – making the process unnecessarily longer and
                      complicated.
                    </p>
                    <br />

                    <p className="description">
                      The payment gateway that was being used only supported the M-pesa payment
                      option asynchronously via callbacks. To fit it inside the synchronous process
                      of booking rides, the team implemented a polling mechanism on the backend to
                      identify when a callback from the payment gateway had been received. This
                      helped in establishing synchronously whether a transaction was successful or
                      not.
                    </p>
                    <br />
                    <p className="description">
                      We managed to implement this solution within the tight deadlines and achieved
                      all our targets. The team ensured a seamless transition with minimal changes
                      to the existing system. There were no contractual updates required with the
                      payment gateways and we were able to plug it into the synchronous
                      implementation. The adoption rate of M-pesa showed a sharp rise upon its
                      initial release in the last week of February, 2022 and was evidence of this
                      frictionless introduction of the new payment system.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-11 mr-auto ml-auto">
                      <img className="w-100" src={swvlThree} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">EBANX</div>
                    <p className="description">
                      The existing version of Swvl application had only one payment gateway that was
                      integrated natively into the customer app. The client wanted to integrate
                      multiple payment options as a scalable solution for launching the app in
                      multiple countries across different continents. Having those local payment
                      options was essential since local transaction fees were lower compared to
                      global payment gateways.
                    </p>
                    <br />
                    <p className="description">
                      This was a challenging undertaking since the existing integrations were baked
                      into the application from both the client and backend perspective. The team
                      was required to first decouple the system from existing payment gateways and
                      options and then integrate the new payment option that was more data and
                      configuration-driven.
                    </p>
                    <br />

                    <p className="description">
                      We started by decoupling the existing system in iOS and Android customer apps
                      and on backend APIs. We followed that up by designing a new payments module
                      where all the configuration for payment gateways and options could be stored
                      in the database and was configurable at the city level. The new data-driven
                      approach would make it easier to decide which gateways and options were
                      applicable in each case i.e. showing users payment options that were specific
                      to their geographic locations.
                    </p>
                    <br />
                    <p className="description">
                      Once the new design was finalized, we implemented it in the application. We
                      also integrated a new payment gateway, EBANX, to help prepare for the
                      platform’s launch in Brazil. While designing the new system, our team went the
                      extra mile and created room for future integrations of additional payment
                      gateways and options with minimal changes required.
                    </p>
                    <br />

                    <p className="description">
                      We also made changes to the Travel website to integrate that gateway natively
                      via Card Tokenization. The existing gateway was integrated via web page
                      redirects which prevented a smooth user experience by having users enter their
                      card details every time they wanted to book a ride through the website.
                    </p>
                    <br />
                    <p className="description">
                      Our team completed work on the new design as well as the payment gateway
                      integration on all backend platforms. At the time of this writing, the
                      feature’s release was pending. Once launched, it would have unlocked new
                      opportunities for Swvl by enabling faster integrations of additional payment
                      gateways and options as well as reducing the transaction costs.
                    </p>
                  </div>
                  <div className="text-with-image-container section-margin">
                    <img
                      className="image-container text-center pl-4"
                      style={{height: '300px'}}
                      src={swvlFour}
                      alt="swvl"
                    />
                    <div className="subheading-big">Refund to Source</div>
                    <p className="description">
                      Another key area Swvl was focused on was allowing for cancellation refunds
                      back to the payment source. Their existing system only deposited refunds for
                      canceled bookings into Swvl wallets (in-app credit) and not in customers’ bank
                      accounts or credit cards from where they were originally deducted. That was a
                      major source of customer dissatisfaction.
                    </p>
                    <br />
                    <p className="description">
                      Our team worked on planning and implementing this feature in the Swvl system.
                      We integrated endpoints of the payment gateway to refund either full or
                      partial transaction amounts back to the source of deduction.
                    </p>
                    <br />

                    <p className="description">
                      We conducted beta testing as soon as the feature was deployed. Once it was
                      successfully tested, the new feature was rolled out globally, benefiting
                      thousands of customers by helping them get their refunds back in their
                      accounts.
                    </p>
                  </div>
                  <div className="text-with-image-container section-margin">
                    <div className="image-container-2 ">
                      <img
                        className="pr-4 mb-5"
                        src={swvlFive}
                        alt="swvl"
                        style={{height: '200px'}}
                      />
                    </div>
                    <div className="subheading-big">Partners Portal</div>
                    <p className="description">
                      One of our teams worked on developing a web-based vendor management portal.
                      The portal enabled vendors, who previously had to rely on Swvl operators which
                      oftentimes caused delays, to manage their daily operations themselves. This
                      provided Swvl with a scalable solution by helping reduce their operational and
                      support staff overheads in every market where the portal was adopted.
                    </p>
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex mt-5 section-margin">
                    <div className=" d-flex align-items-center justify-content-center mr-5 order-lg-1 order-2 flex-column">
                      <p className="description">
                        Our engineers started by developing a web portal MVP. After eliciting an
                        encouraging response from the vendors, the team worked on adding additional
                        features to the portal. We worked on its usability enhancement to improve
                        the experience for vendors and try increasing the portal’s adoption rates.
                      </p>
                      <br />

                      <p className="description">
                        The challenges of implementing a new design language and creating new
                        components to support that design notwithstanding, the team delivered
                        results within the target timeframe. Vendors’ adoption of the portal was
                        consistently growing. At the time of this writing, Swvl’s product team was
                        actively onboarding and training vendors on managing their day-to-day
                        operations effectively using this portal.
                      </p>
                    </div>
                    <div className="-flex justify-content-center order-lg-2 order-1">
                      <img className="pl-4 " style={{height: '300px'}} src={swvlSix} alt="jobbox" />
                    </div>
                  </div>
                  <div className="col-lg-9 col-12 mr-auto ml-auto p-0 my-5 section-margin">
                    <img className="w-100" src={quotationBorder} alt="Quotation Border" />
                    <p className="review-body">
                      We had a good session with MK on the vendor portal today. Everyone liked the
                      solution and it could not have been possible without stellar efforts from the
                      team here. I want to extend a sincere thanks to everyone!
                    </p>
                    <div className="d-flex my-3">
                      <img src={swvlClientImageOne} alt="Subhav Narain" />

                      <div className="p-3 m-0 mt-auto mb-auto">
                        <h2 className="review-name m-0" style={{fontWeight: 'bold'}}>
                          Subhav Narain
                        </h2>
                        <p className="review-name mt-0">
                          Senior Product Manager - Marketplace and Supply at Swvl
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">Supply Pricing</div>
                    <p className="description">
                      One of the most significant problems our team helped resolve was automating
                      the payments framework for Swvl captains. Tackling this job was a
                      three-pronged process.
                    </p>
                    <br />
                    <p className="description">
                      Our team was required to first develop a consensus on data sources. The
                      existing payout process involved multiple points of manual input, with data
                      points scattered across different collections, some deprecated while others
                      still unused. As an example, the data input for rides was recorded in
                      kilometers and minutes.
                    </p>
                    <br />

                    <p className="description">
                      Aftering developing a consensus, the team had to digitize all inputs at a
                      single point. The existing framework consisted of a manual process requiring
                      the Swvl team to manually run database queries and input rates per kilometer
                      and minute.
                    </p>
                    <br />
                    <p className="description">
                      Following that, we had to automate the whole computation process to reduce the
                      time required for processing a captain’s payment from seven man-minutes to
                      almost zero.
                    </p>
                    <br />
                    <div className="col-lg-6 mr-auto ml-auto">
                      <p className="description text-center">
                        For context, Cairo alone had over 2500 captains
                      </p>
                      <p className="description-numbers text-center">
                        2500 captains x 7 minutes per captain
                        <br />= 17,500 man minutes
                      </p>
                      <p className="description text-center mt-0">Payouts happened every week</p>
                    </div>
                    <br />
                    <p className="description ">
                      In order to reach a consensus on data sources, the team studied the ERD
                      diagram as well as models defined in the code. Since some fields were
                      deprecated while others were still unused, we conducted several meetings with
                      the Product and Business teams at Swvl to pinpoint exactly where the data was
                      coming from, particularly for Ride Distance in kms and Ride Duration in
                      minutes.
                    </p>
                    <br />
                    <p className="description">
                      After locking in the data sources, we had to move the system away from manual
                      inputs at multiple points in the pipeline (e.g. rates per km and rates per
                      min). To accomplish this, the team devised a digital Rate Card system that
                      would be used to define all the parameters at one point and which the system
                      would use to calculate driver payouts.
                    </p>
                    <br />
                    <p className="description">
                      Once data sources were finalized and payment parameters defined in a card
                      format, the last step was using those to compute payouts for the captains.
                      Towards this end, we implemented a Cron Job-based system that ran every 24
                      hours and computed payouts for the given day. The job aggregated data from
                      finalized input sources, applied the rate card-based value on parameters, and
                      calculated the final payout for each captain every day. For purposes of
                      scaling and easier maintenance, we also spun up a microservice for the Rate
                      Cards and computation.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-6 mr-auto ml-auto">
                      <img className="w-100" src={swvlSevenA} alt="bed bath and beyond" />
                    </div>
                    <div className="col-lg-6 mr-auto ml-auto">
                      <img className="w-100" src={swvlSevenB} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      Our supply pricing squad delivered excellent results. The cron job ran every
                      24 hours, computing payouts for each captain without any human intervention.
                      In the very first release, the payouts were marked 98% accurate by the
                      Business Team. The Job was also configurable to be run per city or per country
                      to allow for timezone flexibility. Overall, our team’s efforts transformed a
                      manual process into a completely automated one, helping conserve thousands of
                      man hours for Swvl.
                    </p>
                  </div>
                  <div className="col-lg-9 col-12 mr-auto ml-auto p-0 section-margin">
                    <img className="w-100" src={quotationBorder} alt="Quotation Border" />
                    <p className="review-body">
                      Very proud of the team (Engineering and Product) that built a brand new
                      product and microservice in a quarter! That is very impressive especially
                      since they were all new to Swvl. Excited for what we are going to achieve in
                      Q2 together. Keep up the great work!
                    </p>
                    <div className="d-flex my-3">
                      <img src={swvlClientImageTwo} alt="Mostafa Zaher" />

                      <div className="p-3 m-0 mt-auto mb-auto">
                        <h2 className="review-name m-0" style={{fontWeight: 'bold'}}>
                          Mostafa Zaher
                        </h2>
                        <p className="review-name mt-0">
                          Senior Engineering Manager - Marketplace at Swvl
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">Dispatcher and ETA Optimization</div>
                  </div>
                  <div className="text-with-image-container">
                    <div className="image-container text-center m-0">
                      <img src={swvlEight} alt="swvl" />
                    </div>
                    <p className="description">
                      Creating a functioning arrival time service was a domain in which Swvl had
                      been unsuccessful despite repeated attempts. Our team’s efforts in three key
                      areas helped overcome this challenge.
                    </p>
                    <br />
                    <p className="description">
                      Firstly, we developed a feature for dispatcher monitoring and made
                      improvements to the dispatcher architecture. Monitoring for the Dispatcher was
                      done daily through Datadog logs. Our engineers added a new “schedule level
                      wait time” feature to assist with this monitoring. The second key area was
                      releasing the Ratimator, a Python machine learning arrival time service. The
                      release had been particularly challenging for Swvl which had had several
                      unsuccessful attempts at releasing the ETA component until that point.
                    </p>
                    <br />
                    <p className="description">
                      We consolidated the architectural components around the Kafka queue for
                      improved performance and maintenance. We also created self-healing pods and
                      similar configurations to improve the health probe. All these implementations
                      resulted in improved logging on Datadog.
                    </p>
                    <br />
                    <p className="description">
                      The second key area was releasing the Ratimator, a Python machine learning
                      arrival time service. The release had been particularly challenging for Swvl
                      which had had several unsuccessful attempts at releasing the ETA component
                      until that point.
                    </p>
                    <br />
                    <p className="description">
                      Our team improved automated testing, added fallback plans to the code, and
                      improved the API communication with the ETA component. We also improved the
                      API logging support to better visualize faults on Datadog. The engineers also
                      worked on adding performance monitoring for the ETA component to calculate the
                      delay between each ETA action.
                    </p>
                    <br />

                    <p className="description">
                      The third and last area involved combining several arrival services into a
                      single API, called the Delta. These included Traffik (a city graph service),
                      Ratimator, OSRM, as well as Google Maps.
                    </p>
                    <br />

                    <p className="description">
                      Throughout this process, our team would create RFDs (Request for Discussion
                      Documents). In every domain that we worked in, we would design these RFDs with
                      several follow-ups with Swvl’s architects. Only once the RFDs were approved
                      through this process would we initiate the development work. Our team’s core
                      expertise involved here included Node.js/Javascript, MongoDB,
                      Kubernetes/Docker, Unit testing, Github actions/Pull requests/Jenkins/Jira,
                      and Python.
                    </p>
                    <br />

                    <p className="description">
                      The Dispatcher and the ETA component helped save Swvl the Google Maps API
                      costs. Our introduction of auto-healing pods ensured a smooth running of
                      application features. The consolidated API contained several APIs of the ETA
                      system together, enabling clients to define the particular API for use on
                      open-ended queries. Meanwhile, the Datadog logging helped improve the logs of
                      the ETA component.
                    </p>
                  </div>
                  <div className="col-12 p-0"></div>
                  <div className="section-margin" />
                </div>
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Swvl;
